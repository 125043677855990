<i18n>
ru:
  change: Изменить
  choose: Выбрать
  chosen: Добавлен
  rangedAvailable: 'Доступен от суммы '
  rangedMore: 'Еще '
  title: Выберите подарок
  subtitlePromocode: 'Вы активировали промокод, который дает вам возможность выбрать одно блюдо в подарок.'
  subtitlePromocodeMultiple: 'Вы активировали промокод, который дает вам возможность выбрать несколько блюд в подарок.'
  subtitleRangedMultiple: Вам доступны следующие подарки
  subtitleRanged: 'Мы подготовили для вас разные варианты, которые становятся доступны в зависимости от суммы заказа в корзине.'
ua:
  change: Змінити
  choose: Вибрати
  chosen: Додано
  rangedAvailable: 'Доступний від суми '
  rangedMore: 'Ще '
  title: Виберіть подарунок!
  subtitlePromocode: 'Ви активували промокод, який дає вам можливість вибрати одну страву в подарунок.'
  subtitlePromocodeMultiple: 'Ви активували промокод, який дає вам можливість вибрати кілька страв в подарунок.'
  subtitleRangedMultiple: Вам доступні наступні подарунки
  subtitleRanged: 'Ми підготували для вас різні варіанти, які стають доступними залежно від суми замовлення в кошику.'
us:
  change: Change
  choose: Choose
  chosen: Added
  rangedAvailable: 'Available from '
  rangedMore: 'Need '
  title: Choose a gift
  subtitlePromocode: You have activated a promo code that gives you ability to choose one gift
  subtitlePromocodeMultiple: You have activated a promo code that gives you ability to choose several gifts.
  subtitleRangedMultiple: Those gifts are available for you
  subtitleRanged: 'We have prepared various options for you, which become available depending on the order amount in the cart.'
</i18n>

<template>
  <div class="v-gifts-popup-wrapper">
    <div
      class="v-popup-head v-title v-gifts-popup-title"
      v-html="translate('giftsPopup.title')"
    />
    <div
      class="v-gifts-popup-subtitle"
      v-html="subtitle"
    />
    <div class="v-gifts-popup-gifts v-scroll-box__300 v-scrollbar">
      <common-cards-flex-mesh
        v-if="(gifts ?? []).length > 0"
        :class="[
          appConfig.VueSettingsPreRun.GiftsPopupNumber > gifts.length
            ? 'v-gifts-popup-gifts__gift-wrapper'
            : ''
        ]"
        :items="giftsLocal"
        :max-items="appConfig.VueSettingsPreRun.GiftsPopupNumber"
        :mesh-gap="appConfig.VueSettingsPreRun.MenuMeshGap"
      >
        <template #item="gift: MultipleGift | GiftRanged">
          <lazy-menu-product-in-list-gift-card
            :allow-multiple="allowMultiple"
            :available-from-cart-sum="(gift as GiftRanged).AvailableFromCartSum ?? 0"
            :gift="findInProducts(gift.GiftProductID)!"
            :gift-max-count="gift.MaxCount"
            :in-cart="inCart"
          />
        </template>
      </common-cards-flex-mesh>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PositionType } from '~api/consts'

import type { CartItem } from '~types/clientStore'
import type { GiftRanged, GiftType, MultipleGift, Product } from '~types/menuStore'

import type { GUID } from '@arora/common'

const { gifts, type, allowMultiple } = defineProps<{
  allowMultiple: boolean
  gifts: GiftRanged[] | MultipleGift[]
  type: GiftType
}>()

const clientStore = useClientStore()
const { translate } = useI18nSanitized()
const appConfig = useAppConfig()

const inCart = computed<CartItem[]>(() =>
  (clientStore.ClientState.data?.Cart?.Content ?? []).filter((item: CartItem) => {
    switch (type) {
      case 'ranged':
        return item.PositionType === PositionType.RangedGift
      case 'promocode':
        return item.PositionType === PositionType.MultipleGift
      default:
        return false
    }
  })
)

const giftsLocal = computed<GiftRanged[] | MultipleGift[]>(() =>
  gifts
    ? [...gifts].sort((a, b) => {
        return 'SortWeight' in a && 'SortWeight' in b && a.SortWeight > b.SortWeight ? 1 : -1 //sort as in the back
      })
    : []
)

function findInProducts(id: GUID): Product | undefined {
  return appConfig.Products.find((product) => product.ID === id)
}

const subtitle = computed<string>(() => {
  switch (type) {
    case 'ranged':
      return allowMultiple
        ? translate('giftsPopup.subtitleRangedMultiple')
        : translate('giftsPopup.subtitleRanged')
    case 'promocode':
      return allowMultiple
        ? translate('giftsPopup.subtitlePromocodeMultiple')
        : translate('giftsPopup.subtitlePromocode')
    default:
      return ''
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';

.v-gifts-popup-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.v-gifts-popup-gifts__gift-wrapper {
  justify-content: center;
}
.v-gifts-popup-title {
  font-weight: 600;
  font-size: variables.$TextSizeH3;
  margin-bottom: 10px;
}

.v-gifts-popup-subtitle {
  font-weight: 400;
  font-size: variables.$TextSizeMain;
  margin-bottom: 1.1rem;
}
</style>
